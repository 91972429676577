import React, { useState, useEffect, useMemo } from "react";
import { Image as DatoImage } from "react-datocms";
import dynamic from "next/dynamic";

import { useIsMobile } from "@/hooks/withIsMobile";
import { isEmpty } from "@/utils/functions";
import { useSelector } from "react-redux";

const CustomComponentSlider = dynamic(() => import("@/components/Sliders/CustomComponentSlider"));

const POST_MEDIA_TYPES = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  CAROUSEL_ALBUM: "CAROUSEL_ALBUM",
};

const instagramMediaFilter = (posts) => {
  if (!posts || posts?.length === 0) return [];

  return posts?.filter((post) => {
    if (post?.children?.length > 0) {
      post.children = instagramMediaFilter(post.children);
    }

    if (post.mediaType === POST_MEDIA_TYPES.CAROUSEL_ALBUM && post?.children?.length === 0) {
      return false;
    }

    if (
      (post.mediaType === POST_MEDIA_TYPES.CAROUSEL_ALBUM && post?.mediaUrl) ||
      (post.mediaType === POST_MEDIA_TYPES.IMAGE && post?.mediaUrl) ||
      (post.mediaType === POST_MEDIA_TYPES.VIDEO && (post?.mediaUrl || post?.thumbnailUrl))
    ) {
      return true;
    }

    return false;
  });
};

const InstagramWidget = () => {
  const { width } = useIsMobile();
  const [posts, setPosts] = useState([]);
  // const [allPosts, setAllPosts] = useState([]);

  const { siteConfig, homePageData } = useSelector((state) => {
    return {
      siteConfig: state?.labelsConfig?.labelsAndConfigData?.siteConfig,
      homePageData: state?.homePage?.homePageData?.home,
    };
  });

  const apiFetcher = async () => {
    const res = await fetch("/api/instagram", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    });
    return res.json();
  };

  useEffect(() => {
    (async () => {
      const feed = await apiFetcher();
      if (feed) {
        setPosts(feed?.posts?.length > 0 ? instagramMediaFilter(feed?.posts || []) : []);
      }
    })();
  }, []);

  // useEffect(() => {
  //   setPosts(allPosts);
  // }, [allPosts, width]);

  const slidesOptions = useMemo(() => {
    return {
      nav: true,
      margin: 30,
      responsive: {
        320: {
          items: 1,
          dots: posts?.length > 1,
        },
        576: {
          items: 3,
          dots: posts?.length > 3,
        },
        992: {
          items: 4,
          dots: posts?.length > 4,
        },
      },
    };
  }, [posts]);

  const childSlidesOptions = useMemo(() => {
    return {
      dots: false,
      navText: [
        "<img src='/owlNavBtn/Arrow_left.svg' alt='Arrow Left' class='!w-[20px] !h-[20px]' />",
        "<img src='/owlNavBtn/Arrow_right.svg' alt='Arrow Right' class='!w-[20px] !h-[20px]' />",
      ],
      navClass: ["instagram-post-prev", "instagram-post-next"],
      nav: true,
      // mouseDrag: false,
      // touchDrag: false,
      items: 1,
    };
  }, []);

  if (posts?.length === 0) return null;

  return (
    <div className="pt-[72px] pb-[52px] flex flex-col gap-y-9">
      <div className="flex xxs:flex-col lg:flex-row gap-x-4 items-center w-max mx-auto">
        <DatoImage
          data={{
            src: siteConfig?.favicon?.url,
            alt: "favicon",
            width: 50,
            height: 50,
            placeholder: !isEmpty(siteConfig?.favicon?.url) ? "blur" : "empty",
            blurDataURL: siteConfig?.favicon?.url,
          }}
        />

        <h3>{homePageData?.igText}</h3>
        <p>{homePageData?.igAbstract}</p>
      </div>
      {!isEmpty(posts) && (
        <CustomComponentSlider
          customOptions={slidesOptions}
          customClasses="container owl-instagram-widget"
        >
          {posts?.map((post) => (
            <div className="flex flex-col shadow-lg rounded-lg pb-3" key={post.id}>
              {/* {post?.children?.length > 0 ? (
              <CustomComponentSlider
                customOptions={{
                  ...childSlidesOptions,
                  nav: post?.children?.length > 1,
                }}
              >
                <>
                  {post?.children?.map((feed) => (
                    <div className="relative h-[250px] w-full" key={feed}>
                      {feed?.mediaType === "IMAGE" ? (
                        <Image
                          src={feed?.mediaUrl}
                          alt="alt"
                          layout="fill"
                          priority={true}
                          unoptimized={true}
                          className="object-cover rounded-t-lg"
                        />
                      ) : (
                        <video
                          className="w-full h-[250px] rounded-t-lg"
                          poster={feed?.thumbnailUrl}
                          controls
                        ></video>
                      )}
                    </div>
                  ))}
                </>
              </CustomComponentSlider>
            ) : ( */}
              {post?.children?.length > 0 ? (
                <CustomComponentSlider
                  customOptions={childSlidesOptions}
                  customClasses="owl-instagram-widget max-h-[250px] h-[250px] owl-tamaro-children"
                >
                  {post?.children?.map((list) => (
                    <div className="relative h-[250px] w-full" key={list?.id}>
                      {list?.mediaType === POST_MEDIA_TYPES.IMAGE && list?.mediaUrl && (
                        <DatoImage
                          data={{
                            src: list?.sizes.small.mediaUrl,
                            alt: "alt",
                            layout: "fill",
                            className: "rounded-t-lg",
                          }}
                          lazyLoad={false}
                          pictureClassName="object-cover"
                          className="h-full w-full"
                        />
                      )}
                      {list?.mediaType === POST_MEDIA_TYPES.VIDEO && list?.mediaUrl && (
                        <video
                          className="w-full h-[250px] rounded-t-lg"
                          poster={list?.sizes.small.mediaUrl}
                          src={list?.mediaUrl}
                          controls
                        />
                      )}
                      {list?.mediaType == POST_MEDIA_TYPES.VIDEO && !list?.mediaUrl && (
                        <DatoImage
                          data={{
                            src: list?.sizes.small.mediaUrl,
                            alt: "alt",
                            layout: "fill",
                            className: "rounded-t-lg",
                          }}
                          lazyLoad={false}
                          pictureClassName="object-cover"
                          className="h-full w-full"
                        />
                      )}
                    </div>
                  ))}
                </CustomComponentSlider>
              ) : (
                <div className="relative h-[250px] w-full">
                  {post?.mediaType === POST_MEDIA_TYPES.IMAGE && post?.mediaUrl && (
                    <DatoImage
                      data={{
                        src: post?.sizes.small.mediaUrl,
                        alt: "alt",
                        layout: "fill",
                        className: "rounded-t-lg",
                      }}
                      lazyLoad={false}
                      pictureClassName="object-cover"
                      className="h-full w-full"
                    />
                  )}
                  {post?.mediaType === POST_MEDIA_TYPES.VIDEO && post?.mediaUrl && (
                    <video
                      className="w-full h-[250px] rounded-t-lg"
                      poster={post?.sizes.small.mediaUrl}
                      src={post?.mediaUrl}
                      controls
                    />
                  )}
                  {post?.mediaType == POST_MEDIA_TYPES.VIDEO && !post?.mediaUrl && (
                    <DatoImage
                      data={{
                        src: post?.sizes.small.mediaUrl,
                        alt: "alt",
                        layout: "fill",
                        className: "rounded-t-lg",
                      }}
                      lazyLoad={false}
                      pictureClassName="object-cover"
                      className="h-full w-full"
                    />
                  )}
                </div>
              )}

              {/* )} */}
              <a href={post?.permalink} target="_blank" rel="noreferrer">
                {/* <div className="flex mt-[14px] gap-x-[12px] items-center px-[12px]">
                <Image
                  src={siteConfig?.favicon?.url}
                  alt="favicon"
                  width={45}
                  height={45}
                  priority={true}
                  unoptimized={true}
                  placeholder={
                    !isEmpty(siteConfig?.favicon?.url) ? "blur" : "empty"
                  }
                  blurDataURL={siteConfig?.favicon?.url}
                />
                <p className="text-xs leading-[18px] font-futuraBold">
                campingtamaroresort
              </p>
              </div> */}
                <p
                  className="text-primary mt-[18px] text-sm leading-[18px] h-[90px] overflow-hidden whitespace-nowrap px-[12px] text-ellipsis"
                  dangerouslySetInnerHTML={{
                    __html: post?.caption?.replace(/\n/g, " <br/> "),
                  }}
                ></p>
                {/* <div className="flex gap-6 justify-center mt-6">
              <div className="flex items-center gap-x-[10px]">
                <div className="md:w-[24px] pr-1 flex a-center  items-center">
                  <Image
                    src="/likes.svg"
                    width={25}
                    height={25}
                    alt={"likes"}
                    unoptimized
                  />
                </div>
                <p className=" font-bold text-xs leading-[18px]">25 likes</p>
              </div>
              <div className="flex items-center  gap-x-[10px]">
                <div className="md:w-[24px] w-[24px] pr-1 flex a-center items-center">
                  <Image
                    src="/comments.svg"
                    width={22}
                    height={20}
                    alt={"comment"}
                    unoptimized
                  />
                </div>
                <p className=" font-bold text-xs leading-[18px]">20 comments</p>
              </div>
            </div> */}
              </a>
            </div>
          ))}
        </CustomComponentSlider>
      )}
    </div>
  );

  // ) : (
  //   <div>Loading...</div>
  // );
};

export default InstagramWidget;
